<template>
  <div v-if="isMobile">
    <div class="my-6">
      <h3 class="font-bold">Criar um novo lançamento</h3>
      <span class="text-sm text-gray-500"
        >Preencha as informações abaixo e clique no botão <b>criar</b> para
        salvar as alterações.
      </span>
    </div>

    <div class="flex mb-6 justify-end">
    <router-link :to="`/financeiro/criarManual/${id_evento}`">
      <Button color="primary" :text="`Criar Manualmente`" id="criarManual"></Button>
    </router-link>
    </div>
    
    
    <div class="card shadow-sm border p-8 bg-white">
      <div class="block">
        <div class="flex-1 my-3 gap-2">   
          <button
            class="bg-primary rounded-md shadow-md transform px-5 py-2 flex justify-center items-center font-bold cursor-pointer w-full disabled:opacity-50"
          >
            <label class="text-white cursor-pointer" for="logo"
              >Adicionar Comprovante</label
            >
            <i class="ml-3 text-white gg-file"></i>
            <input
              @change="changeImage"
              class="absolute opacity-0 bton disabled:opacity-50"
              id="logo"
              type="file"
              style="bottom: -35px; left: -0px"
            />
          </button>
          <button
            class="bg-primary rounded-md shadow-md transform px-5 py-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 mt-2 w-full "
            v-if="data.comprovante"
            @click="() => visualizarComprovante(data.comprovante)"
          >
            <label class="text-white cursor-pointer" for="logo3"
              >Visualizar Comprovante</label
            >
            <i class="ml-3 text-white gg-file"></i>
          </button>
        </div>

         <div class="flex flex-col mt-4 gap-2">
          <!-- tipo e pix -->
          <div class="flex-1">
            <Input
              type="select"
              label="Tipo de Lançamento"
              v-model="data.id_pagamento_tipo"
              :data="tipoPag"
            />
          </div>
          <div class="flex-1">
            <Input type="default" label="PIX" v-model="data.pix" />
          </div>

          <button
          class="bg-primary rounded-md shadow-md transform px-5 py-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 mt-2 w-full "
         
          @click="getDataFromFile"
        >
          <label class="text-white cursor-pointer" for="logo3"
            >Ler Comprovante</label
          >
          <i class="ml-3 text-white gg-file"></i>
        </button>

            <!-- autocomplete -->
            <div class="flex flex-col gap-2" v-if="selectBank">
                <div class="flex-1 w-full ">
                  <Input
                    type="default"
                    placeholder="Valor"
                    label="Valor"
                    v-model="showToUser.valor"
                  />
                </div>
                <div class="flex-1 w-full ">
                  <Input
                    type="default"
                    placeholder="Data"
                    label="Data"
                    v-model="showToUser.data"
                  />
                </div>
                <div class="flex-1 w-full ">
                  <Input
                    type="select"
                    label="Tipo Pessoa"
                    v-model="autoComplete.tipo_pessoa"
                    :data="tipoPessoa"
                  />
                </div>
      
              <div class="flex flex-col gap-1 mt-5">
                <p class=" text-sm text-gray-500">Dados do Favorecido:</p>
                  <div class="flex-1 w-full ">
                    <Input
                      type="default"
                      placeholder="CNPJ"
                      label="CNPJ"
                      v-model="autoComplete.to.cnpj"
                    />
                  </div>
                  <div class="flex-1 w-full ">
                    <Input
                      type="default"
                      placeholder="Nome"
                      label="Nome"
                      v-model="autoComplete.to.name"
                    />
                  </div>
                  <div class="flex-1 w-full ">
                    <Input
                      type="default"
                      placeholder="Agência"
                      label="Agência"
                      v-model="autoComplete.to.agency"
                    />
                  </div>
                  <div class="flex-1 w-full ">
                    <Input
                      type="default"
                      placeholder="Conta"
                      label="Conta"
                      v-model="autoComplete.to.account"
                    />
                  </div>
                  <div class="flex-1 w-full ">
                    <Input
                      type="default"
                      placeholder="Banco"
                      label="Banco"
                      v-model="autoComplete.to.bank"
                    />
                  </div>
              </div>
    
              <div class="flex mt-5 w-full">
                <div class="flex-1 ">
                  <Input
                    type="default"
                    placeholder="Descrição"
                    label="Descrição"
                    v-model="data.descricao"
                  />
                </div>
              </div>
      
              <div class="flex w-auto mt-1">
                <button
                  class="bg-primary rounded-md shadow-md transform px-5 py-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 text-white  w-auto my-2"
                  @click="create"
                >
                  Criar
                </button>
              </div>
            </div>

            <div class="flex flex-col gap-2 mt-3" v-if="noBank">
                <div class="flex-1 w-full">
                  <Input
                    name="Data"
                    v-model="showToUser.data"
                    type="date"
                    label="Data"
                    placeholder="Data"
                  />
                </div>
                <div class="flex-1 w-full ">
                  <Input
                    name="Valor"
                    v-model="showToUser.valor"
                    type="money"
                    label="Valor"
                    placeholder="Valor"
                  />
                </div>
                <div class="flex-1 w-full">
                  <Input
                    name="Descrição"
                    v-model="data.descricao"
                    type="default"
                    label="Descrição"
                    placeholder="Descrição"
                  />
                </div>
              <div class="flex w-full mt-1">
                <button
                  class="bg-primary rounded-md shadow-md transform px-5 py-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 text-white  w-auto my-2"
                  @click="create"
                >
                  Criar
                </button>
              </div>
            </div>
      
            <!-- preencher manualmente -->
            <div class="flex flex-col gap-2 mt-3" v-if="manual">
                <div class="flex-1 w-full ">
                  <Input
                    type="money"
                    placeholder="Valor"
                    label="Valor"
                    v-model="showToUser.valor"
                  />
                </div>
                <div class="flex-1 w-full ">
                  <Input
                    type="date"
                    placeholder="Data"
                    label="Data"
                    v-model="data.data"
                  />
                </div>
                <div class="flex-1 w-full">
                  <Input
                    type="select"
                    label="Tipo Pessoa"
                    v-model="autoComplete.tipo_pessoa"
                    :data="tipoPessoa"
                  />
                </div>
      
              <p class="text-sm  my-2 text-gray-500">Dados do Favorecido:</p>
                <div class="flex-1 w-full ">
                  <Input
                    type="default"
                    placeholder="CNPJ"
                    label="CNPJ"
                    v-model="autoComplete.to.cnpj"
                  />
                </div>
                <div class="flex-1 w-full ">
                  <Input
                    type="default"
                    placeholder="Nome"
                    label="Nome"
                    v-model="autoComplete.to.name"
                  />
                </div>
                <div class="flex-1 w-full ">
                  <Input
                    type="default"
                    placeholder="Agência"
                    label="Agência"
                    v-model="autoComplete.to.agency"
                  />
                </div>
                <div class="flex-1 w-full">
                  <Input
                    type="default"
                    placeholder="Conta"
                    label="Conta"
                    v-model="autoComplete.to.account"
                  />
                </div>
                <div class="flex-1 w-full ">
                  <Input
                    type="default"
                    placeholder="Banco"
                    label="Banco"
                    v-model="autoComplete.to.bank"
                  />
                </div>
                <div class="flex-1 w-full">
                  <Input
                    type="select"
                    placeholder="Tipo de Conta"
                    label="Tipo de Conta"
                    v-model="autoComplete.to.type"
                    :data="tipoConta"
                  />
                </div>
              <div class="flex w-full mt-1">
                <button
                  class="bg-primary rounded-md shadow-md transform px-5 py-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 text-white  w-auto my-2"
                  @click="create"
                >
                  Criar
                </button>
              </div>
            </div>
      
            <!-- PIX -->
            <div class="flex flex-col gap-2 my-1 mt-2" v-if="mostraDados">
                <div class="flex-1 w-full">
                  <Input
                    type="money"
                    placeholder="Valor"
                    label="Valor"
                    v-model="paymentPix.valor"
                  />
                </div>
                <div class="flex-1 w-full ">
                  <Input
                    type="default"
                    placeholder="Data"
                    label="Data"
                    v-model="paymentPix.data"
                  />
                </div>
                <div class="flex-1 w-full">
                  <Input
                    type="default"
                    placeholder="Favorecido"
                    label="Favorecido"
                    v-model="paymentPix.favorecido"
                  />
                </div>
                
                <div class="flex-1 w-full">
                  <Input
                    type="default"
                    placeholder="Descrição"
                    label="Descrição"
                    v-model="paymentPix.descricao"
                  />
                </div>
              <div class="flex w-full mt-8">
                <button
                  class="bg-primary rounded-md shadow-md transform px-5 py-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 text-white   w-full my-2"
                  @click="createPix"
                >
                  Criar
                </button>
              </div>
            </div>

         </div>

        
      </div>
    </div>
  </div>

  <div class="" v-else>
    <div class="my-6">
      <h3 class="font-bold">Criar um novo lançamento</h3>
      <span class="text-sm text-gray-500"
        >Preencha as informações abaixo e clique no botão <b>criar</b> para
        salvar as alterações.
      </span>
    </div>
    <!--<router-link :to="`/financeiro/criar/${obj}`"  >
      <Button color="primary" :text="`Voltar à tela anterior`" class="mb-2"></Button>
    </router-link>-->
    <div class="flex mb-6 justify-end">
    <router-link :to="`/financeiro/criarManual/${route.params.id_evento}`">
      <Button color="primary" :text="`Criar Manualmente`" id="criarManual"></Button>
    </router-link>
    </div>
    <div class="card shadow-sm border p-8 bg-white">
      <div class="flex mt-3">
        <div class="flex relative h-8 mx-3 gap-5 mb-3">
          <button
            class="bg-primary rounded-md shadow-md transform px-5 py-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50"
          >
            <label class="text-white cursor-pointer" for="logo"
              >Adicionar Comprovante</label
            >
            <i class="ml-3 text-white gg-file"></i>
            <input
              @change="changeImage"
              class="absolute opacity-0 bton disabled:opacity-50"
              id="logo"
              type="file"
              style="bottom: -35px; left: -0px"
            />
          </button>

          <button
            v-if="data.comprovante"
            class="bg-primary rounded-md shadow-md transform px-5 py-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50"
            @click="() => visualizarComprovante(data.comprovante)"
          >
            <label class="text-white cursor-pointer" for="logo3"
              >Visualizar Comprovante</label
            >
            <i class="ml-3 text-white gg-file"></i>
          </button>
        </div>
      </div>
      <div class="flex mt-3">
        <!-- tipo -->

        <div class="flex-1 mx-3">
          <Input
            type="select"
            label="Tipo de Lançamento"
            v-model="data.id_pagamento_tipo"
            :data="tipoPag"
          />
        </div>
        <div class="flex-1 mx-3">
          <Input type="default" label="PIX" v-model="data.pix" />
        </div>
      </div>

      <div class="flex w-auto mt-1" v-if="complete" >
        <button
          class="bg-primary rounded-md shadow-md transform px-5 py-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 text-white mx-3 w-auto my-2"
          @click="getDataFromFile"
        >
          Ler Comprovante
        </button>
      </div>

        <!-- autocomplete -->
        <div class="flex flex-col gap-2" v-if="selectBank">
          <div class="flex h-8 my-3">
            <div class="flex-1 mx-3">
              <Input
                type="default"
                placeholder="Valor"
                label="Valor"
                v-model="showToUser.valor"
              />
            </div>
            <div class="flex-1 mx-3">
              <Input
                type="default"
                placeholder="Data"
                label="Data"
                v-model="showToUser.data"
              />
            </div>
            <div class="flex-1 mx-3">
              <Input
                type="select"
                label="Tipo Pessoa"
                v-model="autoComplete.tipo_pessoa"
                :data="tipoPessoa"
              />
            </div>
          </div>
  
          <div class="flex flex-col gap-1 mt-5">
            <p class="mx-3 text-sm text-gray-500">Dados do Favorecido:</p>
            <div class="flex h-8 my-3">
              <div class="flex-1 mx-3">
                <Input
                  type="default"
                  placeholder="CNPJ"
                  label="CNPJ"
                  v-model="showToUser.cnpj"
                />
              </div>
              <div class="flex-1 mx-3">
                <Input
                  type="default"
                  placeholder="Nome"
                  label="Nome"
                  v-model="showToUser.name"
                />
              </div>
              <div class="flex-1 mx-3">
                <Input
                  type="default"
                  placeholder="Agência"
                  label="Agência"
                  v-model="autoComplete.to.agency"
                />
              </div>
            </div>
            <div class="flex h-8 my-5">
              <div class="flex-1 mx-3">
                <Input
                  type="default"
                  placeholder="Conta"
                  label="Conta"
                  v-model="autoComplete.to.account"
                />
              </div>
              <div class="flex-1 mx-3">
                <Input
                  type="default"
                  placeholder="Banco"
                  label="Banco"
                  v-model="autoComplete.to.bank"
                />
              </div>
            </div>
          </div>

          <div class="flex mt-5">
            <div class="flex-1 mx-3">
              <Input
                type="default"
                placeholder="Descrição"
                label="Descrição"
                v-model="data.descricao"
              />
            </div>
          </div>
  
          <div class="flex w-auto mt-1">
            <button
              class="bg-primary rounded-md shadow-md transform px-5 py-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 text-white mx-3 w-auto my-2"
              @click="create"
            >
              Criar
            </button>
          </div>
        </div>


      <div class="flex flex-col gap-2 mt-3" v-if="noBank">
        <div class="flex">
          <div class="flex-1 mx-3">
            <Input
              name="Data"
              v-model="showToUser.data"
              type="date"
              label="Data"
              placeholder="Data"
            />
          </div>
          <div class="flex-1 mx-3">
            <Input
              name="Valor"
              v-model="showToUser.valor"
              type="money"
              label="Valor"
              placeholder="Valor"
            />
          </div>
        </div>
        <div class="flex">
          <div class="flex-1 mx-3">
            <Input
              name="Descrição"
              v-model="data.descricao"
              type="default"
              label="Descrição"
              placeholder="Descrição"
            />
          </div>
        </div>
        <div class="flex w-auto mt-1">
          <button
            class="bg-primary rounded-md shadow-md transform px-5 py-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 text-white mx-3 w-auto my-2"
            @click="create"
          >
            Criar
          </button>
        </div>
      </div>

      <!-- preencher manualmente -->
      <div class="flex flex-col gap-2 mt-3" v-if="manual">
        <div class="flex">
          <div class="flex-1 mx-3">
            <Input
              type="money"
              placeholder="Valor"
              label="Valor"
              v-model="showToUser.valor"
            />
          </div>
          <div class="flex-1 mx-3">
            <Input
              type="date"
              placeholder="Data"
              label="Data"
              v-model="data.data"
            />
          </div>
          <div class="flex-1 mx-3">
            <Input
              type="select"
              label="Tipo Pessoa"
              v-model="autoComplete.tipo_pessoa"
              :data="tipoPessoa"
            />
          </div>
        </div>

        <p class="text-sm mx-3 my-2 text-gray-500">Dados do Favorecido:</p>
        <div class="flex">
          <div class="flex-1 mx-3">
            <Input
              type="default"
              placeholder="CNPJ"
              label="CNPJ"
              v-model="autoComplete.to.cnpj"
            />
          </div>
          <div class="flex-1 mx-3">
            <Input
              type="default"
              placeholder="Nome"
              label="Nome"
              v-model="autoComplete.to.name"
            />
          </div>
          <div class="flex-1 mx-3">
            <Input
              type="default"
              placeholder="Agência"
              label="Agência"
              v-model="autoComplete.to.agency"
            />
          </div>
        </div>
        <div class="flex">
          <div class="flex-1 mx-3">
            <Input
              type="default"
              placeholder="Conta"
              label="Conta"
              v-model="autoComplete.to.account"
            />
          </div>
          <div class="flex-1 mx-3">
            <Input
              type="default"
              placeholder="Banco"
              label="Banco"
              v-model="autoComplete.to.bank"
            />
          </div>
          <div class="flex-1 mx-3">
            <Input
              type="select"
              placeholder="Tipo de Conta"
              label="Tipo de Conta"
              v-model="autoComplete.to.type"
              :data="tipoConta"
            />
          </div>
        </div>
        <div class="flex w-auto mt-1">
          <button
            class="bg-primary rounded-md shadow-md transform px-5 py-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 text-white mx-3 w-auto my-2"
            @click="create"
          >
            Criar
          </button>
        </div>
      </div>

      <!-- PIX -->
      <div class="flex flex-col gap-2 my-1" v-if="mostraDados">
        <div class="flex h-8 mt-5">
          <div class="flex-1 mx-3">
            <Input
              type="money"
              placeholder="Valor"
              label="Valor"
              v-model="paymentPix.valor"
            />
          </div>
          <div class="flex-1 mx-3">
            <Input
              type="default"
              placeholder="Data"
              label="Data"
              v-model="paymentPix.data"
            />
          </div>
        </div>
        <div class="flex h-8 mt-8">
          <div class="flex-1 mx-3">
            <Input
              type="default"
              placeholder="Favorecido"
              label="Favorecido"
              v-model="paymentPix.favorecido"
            />
          </div>
          <!-- <div class="flex-1 mx-3">
            <Input
              type="default"
              placeholder="CPF/CNPJ"
              label="CPF/CNPJ"
              v-model="paymentPix.cnpj"
            />
          </div> -->
        </div>
        <div class="flex h-8 mt-8">
          <div class="flex-1 mx-3">
            <Input
              type="default"
              placeholder="Descrição"
              label="Descrição"
              v-model="paymentPix.descricao"
            />
          </div>
        </div>
        <div class="flex w-auto mt-8">
          <button
            class="bg-primary rounded-md shadow-md transform px-5 py-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 text-white mx-3 w-auto my-2"
            @click="createPix"
          >
            Criar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted, watch } from "vue";
import Input from "../../components/Elements/Input.vue";
import { useRoute, useRouter } from "vue-router";
import { GET, POST } from "@/services/api";
import alert from "@/services/alert";
import { numberToCnpj, toDate, toMoney } from "@/services/formater";
import { isEmpty } from "@/services/validate";
import Button from "../../components/Elements/Button.vue";

export default {
  name: "CriarFinanceiroV2",
  components: {
    Input,
    Button
  },
  data() {
    return {
      isMobile: false,
    };
  },
  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const evento = route.params.id;
    const tipoPag = ref([]);
    const tipoPessoa = ref([
      { label: "Jurídica", value: "j" },
      { label: "Física", value: "f" },
    ]);
    const tipoConta = ref([
      { label: "Corrente", value: 1 },
      { label: "Poupança", value: 2 },
    ]);
    const mostraDados = ref(false);
    const loader = inject("loading");
    const selectBank = ref(false);
    const complete = ref(false);
    const pix = ref(false);
    const noBank = ref(false);
    const bank = ref();
    const manual = ref(false);
    const showToUser = ref({
      valor: "",
      data: "",
      "type_from": "",
      "type_to": "",
    });
    const data = ref({
      "id_evento": "",
      "id_pagamento_tipo": "",
      data: "",
      valor: "",
      comprovante: "",
      pix: "",
      descricao: "",
    });
    const banks = ref([
      { value: 1, label: "Inter" },
      { value: 2, label: "Mercado Pago" },
      { value: 3, label: "Stone" },
    ]);
    const autoComplete = ref({
      valor: "",
      data: "",
      "tipo_pessoa": "",
      to: {
        name: "",
        cnpj: "",
        bank: "",
        agency: "",
        account: "",
        type: "",
      },
    });

    const paymentPix = ref({
      valor: "",
      data: "",
      favorecido: "",
      chave: "",
      descricao: "",
    });

    onMounted(async () => {
      data.value["id_evento"] = route.params.id_evento;
      console.log(route.params.id_evento)
      const perm = await GET("pagamento_tipo");

      perm.forEach((p) =>
        tipoPag.value.push({
          value: p.id,
          label: `${p.tipo} - ${p.type === "1" ? "C" : "D"}`,
        })
      );
    });

    watch(
      () => data.value.id_pagamento_tipo,
      (val) => {
        if (val == 1 || val == 2 || val == 8 || val == 10) {
          complete.value = true;
          selectBank.value = false;
          noBank.value = false;
          manual.value = false;
          pix.value = false;
        } else {
          noBank.value = true;
          complete.value = false;
          selectBank.value = false;
          manual.value = false;
          pix.value = false;
        }
      }
    );

    watch(
      () => data.value.pix,
      (val) => {
        console.log(complete.value)
        if (val != "" && complete.value ) {
          pix.value = true;
          selectBank.value = false;
        } else if(val != "" && !complete.value) {
          pix.value = false;
          selectBank.value = false;
        } else if(val != "" && noBank.value){
          pix.value = false;
          selectBank.value = true;
        }
      }
    );

    //todo: func verificar

    const visualizarComprovante = (file) => {
      if (typeof file != "string") {
        const urlFile = window.URL.createObjectURL(file);

        window.open(urlFile);
      } else if (typeof file == "string") {
        fetch(file)
          .then((resp) => resp.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          })
          .catch(() =>
            alert.open(
              "Error",
              "Não foi possivel recuperar o comprovante",
              "danger"
            )
          );
      } else {
        alert.open(
          "Atenção!",
          "Não existe comprovante para visualizar",
          "warning"
        );
      }
    };

    const postServer = async (object) => {
      loader.open()
      if (
        data.value.pix == "" ||
        data.value.pix == null ||
        data.value.pix == undefined
      ) {
        delete object.pix;
      }
      delete object.comprovante

      if(data.value.descricao == "" || data.value.descricao == null || data.value.descricao == undefined){
        delete object.descricao;
      }

      const validate = isEmpty(object);
      if (validate) {
        alert.open("Atenção!", validate, "warning");
        loader.close()
      } else {
        const objReq = new FormData();
        for (const key in object) {
          objReq.append(key, object[key]);
        }

        const valor = object.valor.split(" ")
        //valor[1] = valor[1].replace(".", "").replace(".", ",")

        console.log(valor)

        objReq.set("data", new Date(object.data).toISOString());
        objReq.set("valor", valor[1] || valor);

        console.log(objReq.get("valor"))

        try {
          const res = await POST("pagamento", objReq);
          const idPag = res[0].generatedMaps[0].id;

          const objBanco = {
            banco: autoComplete.value.to.bank || " ",
            agencia: autoComplete.value.to.agency || " ",
            "tipo_conta": "CC",
            "numero_conta": autoComplete.value.to.account || " ",
            "numero_conta_digito": " ",
            "nome_titular": autoComplete.value.to.name || " ",
            "cpf_cnpj": autoComplete.value.to.cnpj || " ",
            "tipo_pessoa": "f",
            "id_pagamento": idPag,
          };

          const res2 = await POST("pagamento_dados_bancarios", objBanco);

          if (!res2) {
            loader.close();
            alert.open("Atenção!", "Erro ao criar pagamento", "warning");
          }

          alert.open("Sucesso!", `Pagamento criado com sucesso!`, "success");
          router.push("/financeiro/" + route.params.id);
        } catch (error) {
          loader.close();
          console.error(error);
        }
      }
    };

    const create = async () => {
      let object;
      try {
        if (
          data.value.id_pagamento_tipo == 1 ||
          data.value.id_pagamento_tipo == 2 ||
          data.value.id_pagamento_tipo == 8 ||
          data.value.id_pagamento_tipo == 10
        ) {
          if (data.value.comprovante) {
            object = {
              ...data.value,
              file: data.value.comprovante,
            };
            postServer(object);
          } else {
            alert.open("Atenção!", "Insira um comprovante.", "warning");
          }
        } else {
          data.value.data = new Date(showToUser.value.data).toISOString();
          data.value.valor = showToUser.value.valor
          object = { ...data.value };
          console.log(object)
          delete object.comprovante;
          postServer(object);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const createPix = async () => {
      let object;
      let objBanco;
      if (data.value.comprovante) {
      try {
        loader.open();
        paymentPix.value.chave = data.value.pix;
        object = {
          "id_pagamento_tipo": data.value["id_pagamento_tipo"],
          data: data.value.data,
          valor: data.value.valor,
          file: data.value.comprovante,
          pix: data.value.pix,
          "id_evento": route.params.id,
        };

        const validate = isEmpty(object);
        if (validate) {
          loader.close();
          alert.open("Atenção!", validate, "warning");
          return;
        } else {
          const valorFloat = data.value.valor.replace(".", "").replace(",", ".")
          //valorFloat[1] = valorFloat[1].replace(".", "");
          object.valor = valorFloat;

          const formData = new FormData();
          for (const key in object) {
            formData.append(key, object[key]);
          }

            const resposta = await POST("pagamento", formData);
            const idPag = resposta[0].generatedMaps[0].id;

            objBanco = {
              banco: " ",
              agencia: " ",
              "tipo_conta": "CC",
              "numero_conta": " ",
              "numero_conta_digito": " ",
              "nome_titular": paymentPix.value.favorecido,
              "cpf_cnpj": " ",
              "tipo_pessoa": "f",
              "id_pagamento": idPag,
            };
            const validate2 = isEmpty(objBanco);

            if (!validate2) {
              const resposta2 = await POST(
                "pagamento_dados_bancarios",
                objBanco
              );
              alert.open(
                "Sucesso!",
                `Pagamento criado com sucesso!`,
                "success"
              );
              loader.close();
              router.push(`/financeiro/${route.params.id}`);
            }
          }
          } catch (error) {
            loader.close();
            alert.open("Atenção!", error, "danger");
            console.log(error)
          }
        } else {
          alert.open("Atenção!", "Insira um comprovante.", "warning");
        }
    };

    const getDataFromFile = async () => {
      if(data.value.pix == "" || data.value.pix == null || data.value.pix == undefined){
        pix.value = false;
      }
      const type = data.value.id_pagamento_tipo;
      if (data.value.comprovante) {
        const formData = new FormData();
        formData.append("file", data.value.comprovante);
        formData.append("pix", pix.value);
        loader.open();
        try {
          const a = await POST("/pagamento/comprovantes", formData);
          console.log('aqui a: ', a)
          if (a) {
            if(pix.value && complete.value && a){
              mostraDados.value = true;
            }
            if(pix.value){
              selectBank.value = false;
            } else {
              selectBank.value = true;
            }
            if (
              !pix.value
            ) {
              if (a.to.type == null) {
                showToUser.value["type_to"] = " ";
              }
              data.value.valor = a.valor;
              data.value.data = a.data;
              data.value["id_evento"] = route.params.id_evento;
              console.log(data.value)
              showToUser.value = {
                valor: toMoney(a.valor),
                data: toDate(a.data),
                "type_to": a.to.type,
                cnpj: a.to.cnpj,
                name: a.to.name,
              };

              autoComplete.value = {
                valor: a.valor, 
                data: new Date(a.data).toISOString(),
                to: {
                  name: a.to.name,
                  cnpj: a.to.cnpj,
                  bank: a.to.bank,
                  agency: a.to.agency,
                  account: a.to.account,
                  type: a.to?.type?.includes("Poupança") ? 2 : 1,
                },
              };
              manual.value = false;
            } else {
              
              paymentPix.value = {
                valor: toMoney(a.valor),
                data: toDate(a.data),
                favorecido: a.to.name,
                chave: data.value.pix,
              };

              console.info(paymentPix.value)
              data.value.valor = a.valor;
              data.value.data = a.data;
              autoComplete.value.valor = a.valor;
              autoComplete.value.data = new Date(a.data).toISOString();

              showToUser.value = {
                valor: toMoney(a.valor),
                data: toDate(a.data),
                "type_to": a.to.type,
                cnpj: a.to.cnpj,
                name: a.to.name,
              };

              autoComplete.value = {
                valor: a.valor,
                data: new Date(a.data).toISOString(),
                to: {
                  name: a.to.name,
                  cnpj: numberToCnpj(a.to.cnpj),
                  bank: a.to.bank,
                  agency: a.to.agency,
                  account: a.to.account,
                  type: a.to?.type?.includes("Poupança") ? 2 : 1,
                },
              };
            }         
            loader.close();
          }
          alert.open("Atenção!", "Verifique os dados para prosseguir", "alert");
        } catch (error) {
          alert.open("Atenção!", error, "danger");
          loader.close();
          console.log(error);
          return error;
        }
      } else {
        alert.open("Atenção!", "Comprovante não adicionado", "error");
      }
    };

    const changeImage = (event) => {
      const obj = {
        "id_evento": route.params.id_evento,
        "id_pagamento_tipo": data.value["id_pagamento_tipo"],
        data: data.value.data,
        descricao: data.value["descricao"],
        valor: data.value.valor,
        pix: data.value.pix,
        comprovante: event.target.files[0],
      };
      data.value = obj;
    };

    return {
      data,
      tipoPag,
      tipoPessoa,
      tipoConta,
      selectBank,
      banks,
      complete,
      autoComplete,
      noBank,
      bank,
      manual,
      showToUser,
      pix,
      paymentPix,
      changeImage,
      visualizarComprovante,
      getDataFromFile,
      create,
      createPix,
      mostraDados,
      evento,
      route
    };
  },
};
</script>

<style scoped></style>
